<template>
  <v-container fluid>
    <v-img
      contain
      max-width="600"
      src="../assets/mapArt/htt_minnow.jpg"
      style="margin: auto; display: block;"
    ></v-img>
    <v-row
      justify="center"
      align="center"
      style="padding-top:50pt; text-align: center; font-size: 0.75rem"
    >
      <div style="max-width:320pt">
        What if a map of the dominant minnow species in US watersheds were
        stylized like Radiohead's map-based Hail to the Thief album cover?
        <br />
        <br />
        Made in ESRI ArcGIS and MS Paint (MS paint is wonderful).
      </div>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>
